// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abuse-js": () => import("./../src/pages/abuse.js" /* webpackChunkName: "component---src-pages-abuse-js" */),
  "component---src-pages-domain-js": () => import("./../src/pages/domain.js" /* webpackChunkName: "component---src-pages-domain-js" */),
  "component---src-pages-email-marketing-js": () => import("./../src/pages/email-marketing.js" /* webpackChunkName: "component---src-pages-email-marketing-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-gaming-js": () => import("./../src/pages/gaming.js" /* webpackChunkName: "component---src-pages-gaming-js" */),
  "component---src-pages-how-it-works-js": () => import("./../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-store-js": () => import("./../src/pages/online-store.js" /* webpackChunkName: "component---src-pages-online-store-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-terms-acceptable-use-js": () => import("./../src/pages/terms/acceptable-use.js" /* webpackChunkName: "component---src-pages-terms-acceptable-use-js" */),
  "component---src-pages-terms-arbitration-agreement-js": () => import("./../src/pages/terms/arbitration-agreement.js" /* webpackChunkName: "component---src-pages-terms-arbitration-agreement-js" */),
  "component---src-pages-terms-copyright-infringement-policy-js": () => import("./../src/pages/terms/copyright-infringement-policy.js" /* webpackChunkName: "component---src-pages-terms-copyright-infringement-policy-js" */),
  "component---src-pages-terms-data-request-policy-js": () => import("./../src/pages/terms/data-request-policy.js" /* webpackChunkName: "component---src-pages-terms-data-request-policy-js" */),
  "component---src-pages-terms-domain-registration-agreement-js": () => import("./../src/pages/terms/domain-registration-agreement.js" /* webpackChunkName: "component---src-pages-terms-domain-registration-agreement-js" */),
  "component---src-pages-terms-domain-renewal-notification-policy-js": () => import("./../src/pages/terms/domain-renewal-notification-policy.js" /* webpackChunkName: "component---src-pages-terms-domain-renewal-notification-policy-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-professional-services-agreement-js": () => import("./../src/pages/terms/professional-services-agreement.js" /* webpackChunkName: "component---src-pages-terms-professional-services-agreement-js" */),
  "component---src-pages-terms-professional-services-agreement-previous-js": () => import("./../src/pages/terms/professional-services-agreement-previous.js" /* webpackChunkName: "component---src-pages-terms-professional-services-agreement-previous-js" */),
  "component---src-pages-terms-terms-of-service-js": () => import("./../src/pages/terms/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-terms-of-service-js" */),
  "component---src-pages-terms-trademark-disputes-js": () => import("./../src/pages/terms/trademark-disputes.js" /* webpackChunkName: "component---src-pages-terms-trademark-disputes-js" */)
}

